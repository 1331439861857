import { useMemo, useRef } from 'react';
import { useUpdateEffect } from 'ahooks';
import { useModel } from '@@/exports';

export const useMemoShowFunds = (fn: () => any, deps: any[] = []) => {
  const { fundingOverviewSocketMap } = useModel('socket');
  // 总资金
  const lock = useRef(false);
  useUpdateEffect(() => {
    lock.current = true;
    const timer = setTimeout(() => {
      lock.current = false;
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [fundingOverviewSocketMap]);

  const prev = useRef(0);
  return useMemo(() => {
    if (lock.current) return prev.current;
    prev.current = fn();
    return prev.current;
  }, [lock.current, ...deps]);
};