import { TeenyiconsDownSolid } from '@/assets/icons/comm/TeenyiconsDownSolid';
import ProjectSetting from '@/setting/projectSetting';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useState } from 'react';

export default function () {
  const {
    exchange, // 后端返回的数据
    setCurrentExchangeFunds, //资金页面选择
    currentExchangeFunds,
  } = useModel('exchange');

  const [, setConvertCoin] = useState(ProjectSetting.APP_DEFAULT_CURRENCY);

  return (
    <Dropdown classNames={{ content: 'bg-background' }}>
      <DropdownTrigger>
        <div className="flex items-center ml-2 cursor-pointer">
          <span className="text-base mr-1">
            {currentExchangeFunds?.exchange}
          </span>
          <TeenyiconsDownSolid width="0.7em" height="0.7em" />
        </div>
      </DropdownTrigger>
      <DropdownMenu
        className="text-backContrastColor"
        variant="flat"
        closeOnSelect
        disallowEmptySelection
        selectionMode="single"
        onAction={(key: any) => {
          const findCoin = (exchange as any)?.usdExchangeList?.find?.(
            (item: any) => item.id + '' === key,
          );
          if (findCoin) {
            setCurrentExchangeFunds(findCoin);
          }
          setConvertCoin(key);
        }}
        selectedKeys={[currentExchangeFunds?.id + '']}
      >
        {((exchange as any)?.usdExchangeList as any[])
          ?.filter((item) => +item.type === 0)
          ?.map((item) => {
            return (
              <DropdownItem
                onClick={() => {
                  setCurrentExchangeFunds(item);
                }}
                key={item?.id + ''}
              >
                {item.exchange}
              </DropdownItem>
            );
          })}
      </DropdownMenu>
    </Dropdown>
  );
}
