import type { SVGProps } from 'react';

export function Loan(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.7066 11.3161L14.9336 11.1906C14.8328 11.1749 14.7297 11.1659 14.6289 11.1659C14.304 11.1659 13.9813 11.2489 13.6967 11.4124L9.70159 13.6688C9.68815 13.6778 9.67022 13.68 9.65454 13.68C9.63885 13.68 9.62093 13.6755 9.60748 13.6666L8.39751 12.9675H9.41702C9.90101 12.9675 10.2931 12.5753 10.2931 12.0913V11.5827C10.2931 11.0987 9.90101 10.7066 9.41702 10.7066H4.77432C4.69814 10.7066 4.62419 10.7133 4.55473 10.72C4.53681 10.7223 4.52336 10.7245 4.50544 10.7268C4.33963 10.7514 4.21191 10.785 4.21191 10.785L0.0778407 11.9995C0.0285455 12.0152 -0.000583252 12.0667 0.0128609 12.1182L0.897932 15.132C0.909136 15.1723 0.947227 15.1992 0.9898 15.1992C0.998763 15.1992 1.00773 15.1969 1.01669 15.1947L4.01697 14.3141C4.02593 14.3119 4.03489 14.3096 4.04386 14.3096C4.05954 14.3096 4.07747 14.3141 4.09091 14.3231L9.11229 17.2225C9.24897 17.3054 9.37669 17.3323 9.49097 17.3323C9.73968 17.3323 9.91222 17.1979 9.91222 17.1979L15.8859 11.9143C16.101 11.7261 15.9957 11.3654 15.7066 11.3161Z"
        fill="currentColor" />
      <path
        d="M3.97892 9.98062C4.0439 9.96269 4.3912 9.86858 4.77436 9.86858H9.41706C10.2349 9.86858 10.9183 10.4422 11.0909 11.2085C12.6907 10.1935 13.755 8.41214 13.755 6.37759C13.755 3.21822 11.1939 0.65712 8.03456 0.65712C4.87519 0.65712 2.31409 3.21822 2.31409 6.37759C2.31409 7.78699 2.82496 9.07538 3.6697 10.0725L3.97892 9.98062ZM5.59893 6.20282L7.86203 3.93973C7.95838 3.84338 8.11299 3.84338 8.20934 3.93973L10.4724 6.20282C10.5688 6.29917 10.5688 6.45378 10.4724 6.55013L8.20934 8.81322C8.11299 8.90957 7.95838 8.90957 7.86203 8.81322L5.59893 6.55013C5.50258 6.45602 5.50258 6.29917 5.59893 6.20282Z"
        fill="hsl(var(--nextui-primary)" />
    </svg>

  );
}
