import type { SVGProps } from 'react';

export function QuickRecharge(props: SVGProps<SVGSVGElement>) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1 14H4V21L20 21V14H23V24H20H4H3H1V14Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 0H10V9H6L12 15L18 9H14V0Z" fill="hsl(var(--nextui-primary))" />
    </svg>

  );
}
