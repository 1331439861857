import { C2CTransaction } from '@/assets/icons/comm/C2CTransaction';
import { QuickRecharge } from '@/assets/icons/comm/QuickRecharge';
import BaseModal from '@/components/base/baseModal';
import PageEnum from '@/enums/pageEnum';
import { useDisclosure } from '@nextui-org/react';
import { history } from '@umijs/max';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <div onClick={onOpen}>{children}</div>
      <BaseModal isOpen={isOpen} onClose={onClose} title={t('选择充值方式')}>
        <div className="px-4 pb-6 flex flex-col gap-3 pt-4">
          {/* <div
            className="border-1 rounded-md border-borderColor p-4 flex items-center gap-3"
            onClick={(e) => {
              history.push(PageEnum.XRPTOPUP);
              onClose();
              e.stopPropagation();
            }}
          >
            <QuickRecharge />
            <div className="flex flex-col gap-1">
              <div className="text-[16px] text-backContrastColor">
                XRP {t('充值')}
              </div>
              <div className="text-[12px] text-auxiliaryTextColor">
                {t('向您的账户充值数字货币')}
              </div>
            </div>
          </div> */}

          <div
            className="border-1 rounded-md border-borderColor p-4 flex items-center gap-3"
            onClick={(e) => {
              history.push(PageEnum.TopUp);
              onClose();
              e.stopPropagation();
            }}
          >
            <QuickRecharge />
            <div className="flex flex-col gap-1">
              <div className="text-[16px] text-backContrastColor">
                {t('快捷充值')}
              </div>
              <div className="text-[12px] text-auxiliaryTextColor">
                {t('向您的账户充值数字货币')}
              </div>
            </div>
          </div>

          <div
            className="border-1 rounded-md border-borderColor p-4 flex items-center gap-3"
            onClick={(e) => {
              history.push(PageEnum.C2C_LIST);
              onClose();
              e.stopPropagation();
            }}
          >
            <C2CTransaction />
            <div className="flex flex-col gap-1">
              <div className="text-[16px] text-backContrastColor">
                {t('C2C交易')}
              </div>
              <div className="text-[12px] text-auxiliaryTextColor">
                {t('点对点交易，价格从优')}
              </div>
            </div>
          </div>
        </div>
      </BaseModal>
    </>
  );
};
