import type { SVGProps } from 'react';

export function INVITE(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.9808 0.929762C8.1653 0.744137 8.51405 0.437012 9.00005 0.437012C9.4838 0.437012 9.83255 0.744137 10.0193 0.929762C10.098 1.01254 10.172 1.09968 10.2409 1.19076L10.25 1.20216C10.3854 1.37176 10.5229 1.54393 10.7055 1.64076C10.9051 1.74504 11.1517 1.67497 11.3649 1.61436L11.367 1.61376C11.4037 1.6032 11.4404 1.59307 11.4773 1.58339C11.7012 1.52489 12.042 1.43714 12.402 1.48439C12.5937 1.5018 12.7758 1.57581 12.9252 1.69701C13.0011 1.76174 13.0587 1.84526 13.0923 1.93923C13.1258 2.0332 13.1342 2.13432 13.1164 2.23251C13.0827 2.42489 12.9634 2.58239 12.8464 2.73314L12.1771 3.59489L12.1467 3.63426L12.1448 3.63672C11.9834 3.84517 11.8196 4.05676 11.6055 4.21476C11.4357 4.33964 11.2197 4.37451 11.0093 4.37451H6.9908C6.7793 4.37451 6.56442 4.33851 6.39567 4.21476C6.17855 4.05614 6.01542 3.84351 5.8523 3.63426L5.82305 3.59489L5.15367 2.73314C5.03667 2.58239 4.91742 2.42489 4.88367 2.23251C4.86614 2.1345 4.87456 2.0336 4.90811 1.93985C4.94167 1.8461 4.99918 1.76277 5.07492 1.69814C5.22421 1.57653 5.40632 1.50212 5.59805 1.48439C5.95805 1.43714 6.29892 1.52489 6.5228 1.58339C6.55967 1.59308 6.59642 1.6032 6.63305 1.61376L6.63389 1.614C6.84745 1.6747 7.09569 1.74525 7.29455 1.64076C7.47789 1.54298 7.61733 1.36838 7.75406 1.19716L7.75917 1.19076C7.8281 1.09968 7.90209 1.01254 7.9808 0.929762Z"
        fill="hsl(var(--nextui-primary)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0762 12.6703C15.6537 10.1648 14.4459 7.85829 12.627 6.084C12.5516 6.01088 12.5145 5.97488 12.4301 5.913C12.2255 5.77557 11.9945 5.68245 11.7518 5.63963C11.6494 5.625 11.5763 5.625 11.4266 5.625H6.57338C6.42488 5.625 6.35063 5.625 6.24713 5.63963C6.0052 5.68275 5.77493 5.77586 5.57101 5.913C5.49226 5.97038 5.42363 6.03675 5.35388 6.10425C3.54632 7.8767 2.34602 10.1757 1.92488 12.672C1.71113 13.9432 2.20276 15.0604 3.04651 15.84C3.88238 16.6117 5.06701 17.0629 6.30226 17.0629H11.6978C12.933 17.0629 14.1176 16.6129 14.9535 15.84C15.7971 15.0605 16.2876 13.9447 16.0764 12.6726L16.0763 12.6714L16.0762 12.6703ZM12 11.5L9.5 9L7 11.5L9.5 14L12 11.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
