import React from 'react';
import type { SVGProps } from 'react';

export function MaterialSymbolsAssignmentRounded(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h4.2q.325-.9 1.088-1.45T12 1q.95 0 1.713.55T14.8 3H19q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21zm3-4h5q.425 0 .713-.288T14 16q0-.425-.288-.712T13 15H8q-.425 0-.712.288T7 16q0 .425.288.713T8 17m0-4h8q.425 0 .713-.288T17 12q0-.425-.288-.712T16 11H8q-.425 0-.712.288T7 12q0 .425.288.713T8 13m0-4h8q.425 0 .713-.288T17 8q0-.425-.288-.712T16 7H8q-.425 0-.712.288T7 8q0 .425.288.713T8 9m4-4.75q.325 0 .538-.213t.212-.537q0-.325-.213-.537T12 2.75q-.325 0-.537.213t-.213.537q0 .325.213.538T12 4.25"
      ></path>
    </svg>
  );
}