import type { SVGProps } from 'react';

export function C2CTransaction(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12_3246)">
        <path
          d="M7 6.875C6.67167 6.87503 6.34656 6.81039 6.04322 6.68477C5.73988 6.55914 5.46425 6.375 5.23208 6.14285C4.9999 5.9107 4.81573 5.63509 4.69008 5.33176C4.56443 5.02843 4.49976 4.70333 4.49976 4.375C4.49976 4.04667 4.56443 3.72157 4.69008 3.41824C4.81573 3.11491 4.9999 2.8393 5.23208 2.60715C5.46425 2.375 5.73988 2.19085 6.04322 2.06523C6.34656 1.93961 6.67167 1.87497 7 1.875C7.663 1.87506 8.29882 2.13848 8.76761 2.60732C9.2364 3.07615 9.49976 3.712 9.49976 4.375C9.49976 5.038 9.2364 5.67385 8.76761 6.14268C8.29882 6.61152 7.663 6.87494 7 6.875ZM7 6.875H8C9.06087 6.875 10.0783 7.29643 10.8284 8.04657C11.5786 8.79672 12 9.81413 12 10.875V13.125H2V10.875C2 9.81413 2.42143 8.79672 3.17157 8.04657C3.92172 7.29643 4.93913 6.875 6 6.875H7ZM12 19.875C12 18.8141 12.4214 17.7967 13.1716 17.0466C13.9217 16.2964 14.9391 15.875 16 15.875H18C19.0609 15.875 20.0783 16.2964 20.8284 17.0466C21.5786 17.7967 22 18.8141 22 19.875V22.125H12V19.875ZM17 15.875C16.6717 15.875 16.3466 15.8104 16.0432 15.6848C15.7399 15.5591 15.4642 15.375 15.2321 15.1429C14.9999 14.9107 14.8157 14.6351 14.6901 14.3318C14.5644 14.0284 14.4998 13.7033 14.4998 13.375C14.4998 13.0467 14.5644 12.7216 14.6901 12.4182C14.8157 12.1149 14.9999 11.8393 15.2321 11.6071C15.4642 11.375 15.7399 11.1909 16.0432 11.0652C16.3466 10.9396 16.6717 10.875 17 10.875C17.663 10.8751 18.2988 11.1385 18.7676 11.6073C19.2364 12.0762 19.4998 12.712 19.4998 13.375C19.4998 14.038 19.2364 14.6738 18.7676 15.1427C18.2988 15.6115 17.663 15.8749 17 15.875Z"
          fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M24 3V0H21H16V3L21 3V8H24V3Z" fill="hsl(var(--nextui-primary))" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2.62268e-07 21L0 24L3 24L8 24L8 21L3 21L3 16L6.99382e-07 16L2.62268e-07 21Z" fill="hsl(var(--nextui-primary))" />
      </g>
      <defs>
        <clipPath id="clip0_12_3246">
          <rect width="24" height="24" fill="transparent" />
        </clipPath>
      </defs>
    </svg>

  );
}
