import type { SVGProps } from 'react';

export function RegularAitrade(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 14.8106V5.18941C0 4.53259 0.5341 4 1.1928 4H12.8072C13.4659 4 14 4.53259 14 5.18906V14.8106C14 15.4674 13.4659 16 12.8072 16H1.1928C0.5341 16 0 15.4674 0 14.8106ZM5.4901 10.6709L7.3038 11.8028C7.5824 11.9768 7.9443 11.9384 8.17985 11.7096L11.1108 8.86424C11.1778 8.79945 11.2313 8.72186 11.2683 8.636C11.3053 8.55014 11.325 8.45771 11.3263 8.3641C11.3275 8.27049 11.3103 8.17757 11.2755 8.09074C11.2408 8.00392 11.1893 7.92492 11.1241 7.85835C10.9913 7.72304 10.8111 7.64579 10.6224 7.64328C10.4337 7.64077 10.2516 7.71321 10.1153 7.84494L7.5859 10.3007L5.78095 9.17447C5.6472 9.09113 5.49006 9.05426 5.33356 9.0695C5.17707 9.08473 5.02983 9.15123 4.91435 9.25882L2.6348 11.3927C2.56659 11.4562 2.51159 11.5328 2.47301 11.618C2.43443 11.7031 2.41304 11.7952 2.41007 11.8887C2.40711 11.9823 2.42263 12.0756 2.45575 12.163C2.48886 12.2505 2.5389 12.3305 2.60295 12.3982C2.87245 12.6845 3.32395 12.6989 3.61095 12.43L5.4901 10.6709Z"
        fill="currentColor"
      />
      <path
        d="M2 1.5C2.8 1.5 12.3333 1.5 16.5 1.5V12"
        stroke="#1B71EB"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
